import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

// Components
import UserImage from '../../organisms/UserImage'
import ReadingArea from '../../organisms/ReadingArea'
import PatreonArea from '../../organisms/PatreonArea'
import ChangePasswordFrom from '../../organisms/ChangePasswordForm'

// Config
import { account } from '../../../config/localization'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Style
import {
  blueContainer,
  blockContainer,
  gradientWrapper,
  yellowContainer,
} from './styles.module.scss'

const AccountTemplate: React.FC = () => {
  const { language } = useContext(LocalizationContext)
  const [localization, setLocalization] = useState(account.Default)

  useEffect(() => {
    const newLocalization = account[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Cuenta ' : 'Account '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Mantente al tanto de Dilan y tus beneficios de tu subsripción.'
              : 'Keep track of Dilan and your subscription benefits.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage, cuenta'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage, account'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${gradientWrapper}`}>
        <div className={`container ${blockContainer}`}>
          <div className={yellowContainer}>
            <UserImage
              localization={{
                hello_title: localization.hello_title,
                user_since: localization.user_since,
                comment_num: localization.comment_num,
                support_us: localization.support_us,
                keep_reading: localization.keep_reading,
                current_chapter: localization.current_chapter,
                select_image: localization.select_image,
              }}
            />
            <ReadingArea
              localization={{
                keep_reading: localization.keep_reading,
                current_chapter: localization.current_chapter,
              }}
            />
          </div>
          <div className={blueContainer}>
            <PatreonArea
              title={localization.benefits}
              support_us={localization.support_us}
            />
            <ChangePasswordFrom
              localization={{
                title: localization.title,
                password: localization.password,
                passwordNew: localization.passwordNew,
                passwordConfirm: localization.passwordConfirm,
                change: localization.change,
                required: localization.required,
                password_format: localization.password_format,
                min_password: localization.min_password,
                confirm_invalid: localization.confirm_invalid,
                success_change: localization.success_change,
                unexpected_error: localization.unexpected_error,
                invalid_pass: localization.invalid_pass,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountTemplate
